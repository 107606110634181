import React from 'react';

import { ErrorPage } from '../../components/shared/ErrorPage';

const ServiceUnavailable = () => (
  <ErrorPage
    seoTitle="503: Service unavailable"
    title="Služba je momentálně nedostupná"
    description="Jelikož na produktu stále pracujeme, mohlo se nám něco rozbít."
  />
);

export default ServiceUnavailable;
